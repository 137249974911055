import { ArrowRightCircle, MailCheck } from 'lucide-react';
import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const UXUIDesignPage = () => {
  return (
    <>
    <Navbar/>
    <section className="bg-white text-gray-800 py-20">
      <div className="max-w-6xl mx-auto px-4 space-y-12">
        {/* Título y descripción */}
        <header className="text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-600 mb-4">
            Diseño UX/UI
          </h1>
          <p className="text-lg leading-relaxed max-w-2xl mx-auto">
            En <span className="font-semibold">MatuByte</span>, creamos experiencias de usuario intuitivas y atractivas para tus aplicaciones web y móviles. Nuestro enfoque centrado en el usuario garantiza que cada diseño no solo sea visualmente impresionante, sino también funcional y fácil de usar.
          </p>
        </header>

        {/* Imagen y descripción extendida */}
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <img
            src="https://www.doonamis.com/wp-content/uploads/2022/12/UI-UX-Design-1024x576-1.jpg" // Reemplaza con la URL de tu imagen
            alt="Diseño UX/UI"
            className="w-full h-auto rounded-lg shadow-lg"
          />
          <div className="space-y-4">
            <h2 className="text-3xl font-semibold text-blue-600 mb-4">
              Nuestro Proceso de Diseño
            </h2>
            <p className="text-lg leading-relaxed">
              Creemos que el buen diseño comienza con la comprensión profunda de las necesidades del usuario. Nuestro proceso incluye:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-3">
              <li>Investigación de usuarios para entender sus comportamientos y necesidades.</li>
              <li>Wireframing y prototipado para visualizar la interfaz antes del desarrollo.</li>
              <li>Pruebas de usabilidad para asegurar que las soluciones diseñadas sean intuitivas.</li>
              <li>Iteración constante basada en retroalimentación para mejorar el producto final.</li>
            </ul>
          </div>
        </div>

        {/* Sección de Servicios Detallados */}
        <div className="space-y-8">
          <h2 className="text-3xl font-semibold text-center text-blue-600">Servicios de Diseño UX/UI</h2>
          <p className="text-lg leading-relaxed text-center max-w-4xl mx-auto">
            Ofrecemos una gama de servicios diseñados para optimizar la experiencia de usuario en diversas plataformas, que incluyen:
          </p>

          <div className="grid md:grid-cols-3 gap-8 mt-10">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Investigación de Usuarios</h3>
              <p className="text-gray-700 mt-2">
                Realizamos estudios de usuarios para comprender sus necesidades y comportamientos, estableciendo la base para un diseño efectivo.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Prototipado y Wireframing</h3>
              <p className="text-gray-700 mt-2">
                Creamos prototipos interactivos y wireframes que permiten visualizar y probar la interfaz antes del desarrollo final.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Diseño Visual</h3>
              <p className="text-gray-700 mt-2">
                Desarrollamos diseños visuales atractivos que no solo son estéticamente agradables, sino que también mejoran la funcionalidad.
              </p>
            </div>
          </div>
        </div>

        {/* Botones de Contacto */}
        <div className="mt-12 text-center space-y-4">
          <p className="text-xl font-medium text-blue-600">
            ¿Listo para mejorar la experiencia de tus usuarios?
          </p>
          <div className="flex flex-col md:flex-row justify-center gap-6">
            <a
              href="https://wa.me/573023580862" // Cambia esto por tu número de WhatsApp
              className="flex items-center px-6 py-3 text-lg font-medium bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsWhatsapp className="mr-2" /> WhatsApp
            </a>
            <a
              href="mailto:consultoria@matubyte.online"
              className="flex items-center px-6 py-3 text-lg font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <MailCheck className="mr-2" /> Correo Electrónico
            </a>
            <a
              href="tel:+573023580862" // Cambia esto por tu número de teléfono
              className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Contactar <ArrowRightCircle className="ml-2" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <Footer/>    
    </>
  );
};

export default UXUIDesignPage;
