import { ArrowRightCircle, MailCheck } from "lucide-react";
import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroSectionTI from "../heros/HeroSectionTI";

const ITConsultingPage = () => {
  return (
    <div>
      <Navbar />
      <HeroSectionTI/>
      <section className="bg-white text-gray-800 py-20">
        <div className="max-w-6xl mx-auto px-4 space-y-12">
          {/* Título y descripción */}
          <header className="text-center">
            <h1 className="text-5xl font-bold text-blue-600 mb-4">
              Consultoría IT
            </h1>
            <p className="text-lg leading-relaxed max-w-2xl mx-auto">
              En <span className="font-semibold">MatuByte.online</span>,
              ofrecemos consultoría IT para optimizar tus procesos tecnológicos
              y garantizar que tu infraestructura de IT esté alineada con los
              objetivos estratégicos de tu negocio. Nuestro equipo está listo
              para llevar tu empresa al siguiente nivel mediante la innovación y
              la eficiencia.
            </p>
          </header>

          {/* Imagen y descripción extendida */}
          <div className="grid md:grid-cols-2 gap-10 items-center">
            <img
              src="https://www.bernanetwork.com/img/20221212_63970bcb1264e.png" // Reemplaza con la URL de tu imagen
              alt="Consultoría IT"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <div className="space-y-4">
              <h2 className="text-3xl font-semibold text-blue-600 mb-4">
                Nuestro Enfoque
              </h2>
              <p className="text-lg leading-relaxed">
                Nos comprometemos a comprender las necesidades únicas de tu
                negocio. Nuestro enfoque incluye:
              </p>
              <ul className="list-disc list-inside mt-4 space-y-3">
                <li>
                  Evaluación exhaustiva de la infraestructura tecnológica
                  actual.
                </li>
                <li>
                  Recomendaciones personalizadas para optimización y eficiencia.
                </li>
                <li>
                  Implementación de soluciones tecnológicas adaptadas a tus
                  necesidades.
                </li>
                <li>
                  Soporte continuo y mantenimiento para garantizar el éxito a
                  largo plazo.
                </li>
              </ul>
            </div>
          </div>

          {/* Sección de Servicios Detallados */}
          <div className="space-y-8">
            <h2 className="text-3xl font-semibold text-center text-blue-600">
              Servicios de Consultoría IT
            </h2>
            <p className="text-lg leading-relaxed text-center max-w-4xl mx-auto">
              Ofrecemos una variedad de servicios que se adaptan a las
              necesidades específicas de tu empresa:
            </p>

            <div className="grid md:grid-cols-3 gap-8 mt-10">
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="text-xl font-semibold text-blue-600">
                  Auditoría de IT
                </h3>
                <p className="text-gray-700 mt-2">
                  Realizamos auditorías para identificar áreas de mejora en tu
                  infraestructura tecnológica.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="text-xl font-semibold text-blue-600">
                  Gestión de Proyectos
                </h3>
                <p className="text-gray-700 mt-2">
                  Supervisamos tus proyectos de IT para garantizar que se
                  entreguen a tiempo y dentro del presupuesto.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="text-xl font-semibold text-blue-600">
                  Estrategia Digital
                </h3>
                <p className="text-gray-700 mt-2">
                  Desarrollamos estrategias digitales que alinean la tecnología
                  con tus objetivos empresariales.
                </p>
              </div>
            </div>
          </div>

          {/* Botones de Contacto */}
          <div className="mt-12 text-center space-y-4">
            <p className="text-xl font-medium text-blue-600">
              ¿Listo para transformar tu infraestructura IT?
            </p>
            <div className="flex flex-col md:flex-row justify-center gap-6">
              <a
                href="https://wa.me/573023580862" // Cambia esto por tu número de WhatsApp
                className="flex items-center px-6 py-3 text-lg font-medium bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsWhatsapp className="mr-2" /> WhatsApp
              </a>
              <a
                href="mailto:consultoria@matubyte.online"
                className="flex items-center px-6 py-3 text-lg font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <MailCheck className="mr-2" /> Correo Electrónico
              </a>
              <a
                href="tel:+573023580862" // Cambia esto por tu número de teléfono
                className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Contactar <ArrowRightCircle className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ITConsultingPage;
