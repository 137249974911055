import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Server, Code, Database } from 'lucide-react';

const HeroSectionTI = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Iconos flotantes para el fondo
  const FloatingIcon = ({ icon: Icon, delay, position }) => (
    <div
      className={`absolute ${position} text-blue-300/20 transform hover:scale-110 transition-all duration-500
        animate-float opacity-0 ${isVisible ? 'opacity-100' : ''}`}
      style={{ animationDelay: delay }}
    >
      <Icon size={32} />
    </div>
  );

  return (
    <section
      id="inicio"
      className="relative bg-cover bg-center bg-fixed text-white min-h-screen flex items-center overflow-hidden"
      style={{ 
        backgroundImage: "url('https://www.nubaj.com/blog/wp-content/uploads/2019/02/consultor-ti.jpg')",
      }}
    >
      {/* Overlay con gradiente tecnológico */}
      <div className="absolute inset-0 bg-gradient-to-br from-black/80 via-blue-900/50 to-black/70" />

      {/* Iconos flotantes de fondo */}
      <FloatingIcon icon={Server} delay="0s" position="top-1/4 left-1/4" />
      <FloatingIcon icon={Code} delay="0.5s" position="top-1/3 right-1/4" />
      <FloatingIcon icon={Database} delay="1s" position="bottom-1/4 left-1/3" />

      <div className="relative w-full py-20">
        <div className="max-w-6xl mx-auto px-4">
          <div className={`space-y-8 transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'} transition-all duration-1000 ease-out`}>
            {/* Badge animado */}
            <div className="inline-block">
              <span className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/20 text-blue-300 
                border border-blue-400/20 text-sm font-medium animate-[slideIn_0.5s_ease-out]">
                <Server className="w-4 h-4 mr-2" />
                Soluciones IT Avanzadas
              </span>
            </div>

            {/* Título principal con efecto typing */}
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold tracking-tight leading-tight">
              {'Potencia Tu Infraestructura con'.split(' ').map((word, i) => (
                <span
                  key={i}
                  className={`inline-block transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'} transition-all duration-700`}
                  style={{ transitionDelay: `${i * 100}ms` }}
                >
                  {word}{' '}
                </span>
              ))}
              <span className="text-blue-300 inline-block transform hover:scale-105 transition-transform duration-300 
                border-b-2 border-blue-300/0 hover:border-blue-300/100">
                Consultoría IT
              </span>
            </h1>

            {/* Descripción con efecto fade */}
            <p className={`text-xl md:text-2xl max-w-2xl leading-relaxed text-gray-200
              transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              transition-all duration-700 delay-300`}>
              En <span className="text-blue-300 font-semibold">MatuByte.online</span>, 
              brindamos soluciones tecnológicas innovadoras y estrategias personalizadas 
              para optimizar tus operaciones y alcanzar la excelencia en IT.
            </p>

            {/* Botones con efectos tecnológicos */}
            <div className={`flex flex-col sm:flex-row gap-4 pt-6
              transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              transition-all duration-700 delay-500`}>
              <a
                href="#agendar"
                className="group relative inline-flex items-center px-8 py-4 text-lg font-medium 
                  bg-blue-600 text-white rounded-lg overflow-hidden
                  transform hover:scale-105 hover:bg-blue-700
                  transition-all duration-300 ease-out
                  shadow-lg hover:shadow-blue-500/50"
              >
                <span className="relative z-10">Comenzar Proyecto</span>
                <ArrowRight className="ml-2 relative z-10 transform group-hover:translate-x-1 transition-transform" />
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 
                  transform group-hover:scale-110 transition-transform duration-500" />
              </a>
              <Link
                to="/matubyte/services"
                className="group inline-flex items-center px-8 py-4 text-lg font-medium 
                  border-2 border-white text-white rounded-lg 
                  hover:bg-white hover:text-blue-600
                  transform hover:scale-105
                  transition-all duration-300 ease-out
                  relative overflow-hidden"
              >
                <span className="relative z-10">Conocer Más</span>
                <div className="absolute inset-0 bg-white transform -translate-x-full 
                  group-hover:translate-x-0 transition-transform duration-300" />
              </Link>
            </div>
          </div>
        </div>

        {/* Onda tecnológica animada */}
        <div className="absolute bottom-0 left-0 right-0 overflow-hidden">
          <svg
            className="w-full h-24 transform translate-y-1"
            viewBox="0 0 1440 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-white"
              d="M0,32L48,37.3C96,43,192,53,288,58.7C384,64,480,64,576,58.7C672,53,768,43,864,37.3C960,32,1056,32,1152,37.3C1248,43,1344,53,1392,58.7L1440,64L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z"
            >
              <animate
                attributeName="d"
                dur="4s"
                repeatCount="indefinite"
                values="M0,32L48,37.3C96,43,192,53,288,58.7C384,64,480,64,576,58.7C672,53,768,43,864,37.3C960,32,1056,32,1152,37.3C1248,43,1344,53,1392,58.7L1440,64L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z;
                       M0,42L48,37.3C96,33,192,43,288,48.7C384,54,480,54,576,48.7C672,43,768,33,864,37.3C960,42,1056,42,1152,37.3C1248,33,1344,43,1392,48.7L1440,54L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z"
              />
            </path>
          </svg>
        </div>
      </div>

      {/* Estilos para animaciones */}
      <style jsx global>{`
        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes float {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(-20px);
          }
          100% {
            transform: translateY(0px);
          }
        }

        .animate-float {
          animation: float 6s ease-in-out infinite;
        }
      `}</style>
    </section>
  );
};

export default HeroSectionTI;