// HeroSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const HeroSection = () => {
  return (
    <section
      id="inicio"
      className="relative bg-gradient-to-r from-blue-600 to-blue-800 text-white pt-20"
    >
      <div className="max-w-6xl mx-auto px-4 py-20 sm:py-32">
        <div className="animate-on-scroll opacity-0 transition-all duration-1000 space-y-6">
          <h1 className="text-4xl md:text-6xl font-bold tracking-tight">
            Transformamos Ideas en
            <span className="text-blue-300"> Soluciones Digitales</span>
          </h1>
          <p className="text-xl md:text-2xl max-w-2xl">
            En MatuByte.online, convertimos tu visión en realidad con
            desarrollo de software innovador y soluciones tecnológicas de
            vanguardia.
          </p>
          <div className="flex gap-4 pt-6">
            <a
              href="#agendar"
              className="inline-flex items-center px-6 py-3 text-lg font-medium bg-white text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
            >
              Comenzar Proyecto <ArrowRight className="ml-2" />
            </a>
            <Link
              to="/matubyte/services"
              className="inline-flex items-center px-6 py-3 text-lg font-medium border-2 border-white text-white hover:bg-white/10 rounded-lg transition-colors"
            >
              Conocer Más
            </Link>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0">
        <svg
          className="w-full h-24"
          viewBox="0 0 1440 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0,32L48,37.3C96,43,192,53,288,58.7C384,64,480,64,576,58.7C672,53,768,43,864,37.3C960,32,1056,32,1152,37.3C1248,43,1344,53,1392,58.7L1440,64L1440,74L1392,74C1344,74,1248,74,1152,74C1056,74,960,74,864,74C768,74,672,74,576,74C480,74,384,74,288,74C192,74,96,74,48,74L0,74Z"
            fill="white"
          />
        </svg>
      </div>
    </section>
  );
};

export default HeroSection;
