import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/servicesData';
import TechnologiesSection from './components/TechnologiesSection';
import PortfolioSection from './components/projects';
import ContactSection from './components/ContactSection';
import BusinessConsultingPage from './services/BusinessConsultingPage';
import SoftwareDevelopmentPage from './services/SoftwareDevelopmentPage';
import UXUIDesignPage from './services/UXUIDesignPage';
import MobileAppDevelopmentPage from './services/MobileAppDevelopmentPage';
import ITConsultingPage from './services/ITConsultingPage';
// import Portfolio from './pages/Portfolio';
// import About from './pages/About';
// import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/matubyte/services" element={<Services />} />
        <Route path="/matubyte/tecnologias" element={<TechnologiesSection />} />
        <Route path="/matubyte/projects" element={<PortfolioSection />} />
        <Route path="/matubyte/contact" element={<ContactSection />} />
        <Route path="/matubyte/services/business" element={<BusinessConsultingPage />} />
        <Route path="/matubyte/services/software/development" element={<SoftwareDevelopmentPage />} />
        <Route path="/matubyte/services/uxui-design" element={<UXUIDesignPage />} />
        <Route path="/matubyte/services/mobile/development" element={<MobileAppDevelopmentPage />} />
        <Route path="/matubyte/services/it/consulting" element={<ITConsultingPage />} />
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
