// PortfolioSection.js
import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

const projects = [
  {
    title: 'E-commerce Platform',
    description: 'Desarrollamos una plataforma de comercio electrónico moderna y escalable.',
    image: 'https://via.placeholder.com/400x250',
  },
  {
    title: 'Aplicación de Gestión de Inventarios',
    description: 'Aplicación de inventario para mejorar la administración y seguimiento de productos.',
    image: 'https://via.placeholder.com/400x250',
  },
  {
    title: 'Dashboard de Análisis de Datos',
    description: 'Dashboard de datos que facilita la toma de decisiones basada en análisis avanzados.',
    image: 'https://via.placeholder.com/400x250',
  },
  {
    title: 'Aplicación de Chat en Tiempo Real',
    description: 'Sistema de mensajería instantánea para mejorar la comunicación en equipos.',
    image: 'https://via.placeholder.com/400x250',
  },
  // Agrega más proyectos según necesites
];

const PortfolioSection = () => {
  return (
    <>
    <Navbar/>
    <section id="portafolio" className="py-20 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-12">Nuestro Portafolio</h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800">{project.title}</h3>
                <p className="text-gray-600 mt-2">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default PortfolioSection;
