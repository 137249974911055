// ContactSection.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí iría la lógica para enviar los datos del formulario, como una llamada a una API o integración de correo electrónico.
    console.log("Datos del formulario:", formData);
    alert("¡Gracias por contactarnos!");
  };

  return (
    <>
      <Navbar />
      <section id="contacto" className="py-20 bg-blue-50">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold text-blue-600 mb-12">
            Contáctanos
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Información de contacto */}
            <div className="flex flex-col space-y-6 text-left">
              <p className="text-gray-700 text-lg">
                Si tienes alguna pregunta o quieres saber más sobre nuestros
                servicios, no dudes en escribirnos.
              </p>
              <div>
                <p className="font-semibold text-blue-600">Email:</p>
                <p className="text-gray-700">contacto@matubyte.online</p>
              </div>
              <div>
                <p className="font-semibold text-blue-600">Teléfono:</p>
                <p className="text-gray-700">+123 456 7890</p>
              </div>
            </div>

            {/* Formulario de contacto */}
            <form
              onSubmit={handleSubmit}
              className="bg-white p-8 rounded-lg shadow-md"
            >
              <div className="mb-6">
                <label
                  htmlFor="name"
                  className="block text-gray-600 font-semibold mb-2"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-gray-600 font-semibold mb-2"
                >
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block text-gray-600 font-semibold mb-2"
                >
                  Mensaje
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Enviar Mensaje
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactSection;
