import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const HeroSectionSoftware = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 to-slate-800 text-white px-4">
      <div className="max-w-4xl mx-auto text-center space-y-8">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in-up">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-violet-500 hover:scale-105 transition-transform duration-300">
            Desarrollamos Soluciones de Software Innovador
          </span>
        </h1>
        
        <p className="text-lg md:text-xl text-gray-300 max-w-2xl mx-auto mb-8 animate-fade-in hover:text-white transition-colors duration-300">
          En MatuByte.online, creamos software a medida que mejora la eficiencia 
          y el rendimiento de tu negocio, adaptándonos a tus necesidades específicas.
        </p>

        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center animate-fade-in-up">
          <Link 
            to="/contacto"
            className="group flex items-center gap-2 bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg font-semibold transform hover:scale-105 transition-all duration-300"
          >
            Comenzar Proyecto
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
          </Link>

          <Link 
            to="/servicios"
            className="group flex items-center gap-2 bg-transparent border-2 border-blue-500 hover:border-blue-400 px-6 py-3 rounded-lg font-semibold transform hover:scale-105 transition-all duration-300"
          >
            Conocer Más
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionSoftware;