import { ArrowRightCircle, MailCheck } from 'lucide-react';
import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroSectionSoftware from '../heros/HeroSectionSoftware';

const SoftwareDevelopmentPage = () => {
  return (
    <>
    <Navbar/>
    <HeroSectionSoftware/>
    <section className="bg-gray-100 text-gray-800 py-20">
      <div className="max-w-6xl mx-auto px-4 space-y-12">
        {/* Título y descripción */}
        <header className="text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-600 mb-4">
            Desarrollo de Software
          </h1>
          <p className="text-lg leading-relaxed max-w-2xl mx-auto">
            En <span className="font-semibold">MatuByte.online</span>, desarrollamos soluciones a medida para mejorar la eficiencia y el rendimiento de tu negocio. Nos enfocamos en crear software innovador que se adapte a las necesidades específicas de tu empresa, impulsando su crecimiento y competitividad.
          </p>
        </header>

        {/* Imagen y descripción extendida */}
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvpOY9ppHvRdk3CMjMkhAeUkPwNvh6bZVoHw&s" // Reemplaza con la URL de tu imagen
            alt="Desarrollo de software"
            className="w-full h-auto rounded-lg shadow-lg"
          />
          <div className="space-y-4">
            <h2 className="text-3xl font-semibold text-blue-600 mb-4">
              Nuestra Metodología
            </h2>
            <p className="text-lg leading-relaxed">
              Nuestro enfoque se basa en entender a fondo los procesos y requerimientos de tu negocio. Desde la planificación hasta la implementación, nuestro equipo colabora contigo para desarrollar soluciones que realmente marquen la diferencia. Ofrecemos:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-3">
              <li>Desarrollo ágil de software con iteraciones rápidas y eficientes.</li>
              <li>Integración de tecnología de vanguardia y herramientas de gestión.</li>
              <li>Pruebas exhaustivas para garantizar la calidad y el rendimiento.</li>
              <li>Soporte continuo y mantenimiento post-lanzamiento.</li>
            </ul>
          </div>
        </div>

        {/* Sección de Servicios Detallados */}
        <div className="space-y-8">
          <h2 className="text-3xl font-semibold text-center text-blue-600">Servicios de Desarrollo de Software</h2>
          <p className="text-lg leading-relaxed text-center max-w-4xl mx-auto">
            Ofrecemos una gama de servicios personalizados para satisfacer las necesidades de tu negocio, que incluyen:
          </p>

          <div className="grid md:grid-cols-3 gap-8 mt-10">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Aplicaciones Web</h3>
              <p className="text-gray-700 mt-2">
                Creamos aplicaciones web robustas y escalables para mejorar la experiencia del usuario y optimizar procesos.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Desarrollo Móvil</h3>
              <p className="text-gray-700 mt-2">
                Desarrollamos aplicaciones móviles nativas e híbridas que capturan la atención de tus clientes en cualquier dispositivo.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Sistemas a Medida</h3>
              <p className="text-gray-700 mt-2">
                Ofrecemos soluciones de software personalizadas que se adaptan a las necesidades específicas de tu negocio.
              </p>
            </div>
          </div>
        </div>

        {/* Botones de Contacto */}
        <div className="mt-12 text-center space-y-4">
          <p className="text-xl font-medium text-blue-600">
            ¿Listo para llevar tu proyecto al siguiente nivel?
          </p>
          <div className="flex flex-col md:flex-row justify-center gap-6">
            <a
              href="https://wa.me/573023580862" // Cambia esto por tu número de WhatsApp
              className="flex items-center px-6 py-3 text-lg font-medium bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsWhatsapp className="mr-2" /> WhatsApp
            </a>
            <a
              href="mailto:consultoria@matubyte.online"
              className="flex items-center px-6 py-3 text-lg font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <MailCheck className="mr-2" /> Correo Electrónico
            </a>
            <a
              href="tel:+573023580862" // Cambia esto por tu número de teléfono
              className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Contactar <ArrowRightCircle className="ml-2" />
            </a>
          </div>
        </div>
      </div>
    </section>  
    <Footer/>
    </>
  );
};

export default SoftwareDevelopmentPage;
