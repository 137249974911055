import { ArrowRightCircle, MailCheck } from 'lucide-react';
import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const MobileAppDevelopmentPage = () => {
  return (
    <div>
        <Navbar/>
        <section className="bg-white text-gray-800 py-20">
      <div className="max-w-6xl mx-auto px-4 space-y-12">
        {/* Título y descripción */}
        <header className="text-center">
          <h1 className="text-5xl font-bold text-blue-600 mb-4">
            Desarrollo de Apps Móviles
          </h1>
          <p className="text-lg leading-relaxed max-w-2xl mx-auto">
            En <span className="font-semibold">MatuByte.online</span>, construimos aplicaciones móviles nativas y multiplataforma para iOS y Android. Nuestro equipo de expertos combina creatividad y tecnología para ofrecer soluciones que cautiven a tus usuarios y mejoren la interacción con tu marca.
          </p>
        </header>

        {/* Imagen y descripción extendida */}
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <img
            src="https://img.freepik.com/vector-premium/desarrollo-aplicaciones-moviles-telefonos-3d-handhold-creacion-aplicaciones-pruebas-aplicaciones-ui-ux-diseno-web-fondo-abstracto-objeto-3d-3d-ilustracion-vectorial_145666-1589.jpg " // Reemplaza con la URL de tu imagen
            alt="Desarrollo de aplicaciones móviles"
            className="w-full h-auto rounded-lg shadow-lg"
          />
          <div className="space-y-4">
            <h2 className="text-3xl font-semibold text-blue-600 mb-4">
              Nuestro Enfoque
            </h2>
            <p className="text-lg leading-relaxed">
              Nos enfocamos en entender las necesidades de tus usuarios y los objetivos de tu negocio. Nuestro proceso incluye:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-3">
              <li>Desarrollo ágil que permite adaptaciones rápidas durante el proceso.</li>
              <li>Diseño centrado en el usuario para una experiencia óptima.</li>
              <li>Integración de las últimas tecnologías y herramientas de desarrollo.</li>
              <li>Pruebas exhaustivas para asegurar la calidad y el rendimiento de la aplicación.</li>
            </ul>
          </div>
        </div>

        {/* Sección de Servicios Detallados */}
        <div className="space-y-8">
          <h2 className="text-3xl font-semibold text-center text-blue-600">Servicios de Desarrollo de Apps Móviles</h2>
          <p className="text-lg leading-relaxed text-center max-w-4xl mx-auto">
            Ofrecemos una amplia gama de servicios de desarrollo de aplicaciones móviles, que incluyen:
          </p>

          <div className="grid md:grid-cols-3 gap-8 mt-10">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Aplicaciones Nativas</h3>
              <p className="text-gray-700 mt-2">
                Creamos aplicaciones específicas para iOS y Android, optimizadas para un rendimiento superior en cada plataforma.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Aplicaciones Multiplataforma</h3>
              <p className="text-gray-700 mt-2">
                Desarrollamos aplicaciones que funcionan en múltiples sistemas operativos, ahorrando tiempo y recursos.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <h3 className="text-xl font-semibold text-blue-600">Mantenimiento y Actualizaciones</h3>
              <p className="text-gray-700 mt-2">
                Ofrecemos servicios de mantenimiento y actualizaciones para asegurar que tu aplicación se mantenga relevante y segura.
              </p>
            </div>
          </div>
        </div>

        {/* Botones de Contacto */}
        <div className="mt-12 text-center space-y-4">
          <p className="text-xl font-medium text-blue-600">
            ¿Listo para llevar tu idea al mundo móvil?
          </p>
          <div className="flex flex-col md:flex-row justify-center gap-6">
            <a
              href="https://wa.me/573023580862" // Cambia esto por tu número de WhatsApp
              className="flex items-center px-6 py-3 text-lg font-medium bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsWhatsapp className="mr-2" /> WhatsApp
            </a>
            <a
              href="mailto:consultoria@matubyte.online"
              className="flex items-center px-6 py-3 text-lg font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <MailCheck className="mr-2" /> Correo Electrónico
            </a>
            <a
              href="tel:+573023580862" // Cambia esto por tu número de teléfono
              className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Contactar <ArrowRightCircle className="ml-2" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    </div>
  );
};

export default MobileAppDevelopmentPage;
