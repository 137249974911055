import { ArrowRightCircle, MailCheck } from "lucide-react";
import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeroSectionBusiness from "../heros/HeroSectionBusiness";

const BusinessConsultingPage = () => {
  return (
    <div>
      <Navbar />
      <HeroSectionBusiness/>
      <section className="bg-gray-100 text-gray-800 py-20">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 space-y-8 md:space-y-12">
          {/* Título y descripción */}
          <header className="text-center px-4">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-600 mb-4">
              Consultoría de Negocios
            </h1>
            <p className="text-base md:text-lg leading-relaxed max-w-2xl mx-auto">
              En <span className="font-semibold">MatuByte.online</span>, nos
              enfocamos en transformar tu visión de negocio en una realidad
              estratégica y rentable. Nuestro equipo de consultoría te acompaña
              para identificar las oportunidades clave y afrontar los desafíos
              del mercado actual.
            </p>
          </header>

          {/* Imagen y descripción extendida */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10 items-center px-4">
            <img
              src="https://estrategias-marketing-online.com/wp-content/uploads/Como-empezar-un-negocio-de-consultoria-rentable.jpg"
              alt="Consultoría de negocios"
              className="w-full h-auto rounded-lg shadow-lg object-cover"
            />
            <div className="space-y-4">
              <h2 className="text-2xl md:text-3xl font-semibold text-blue-600">
                Nuestro Enfoque Estratégico
              </h2>
              <p className="text-base md:text-lg leading-relaxed">
                Desde la planificación hasta la implementación, nos sumergimos
                en el núcleo de tu negocio para desarrollar estrategias que no
                solo cumplan con tus expectativas, sino que las superen.
              </p>
              <ul className="list-disc list-inside space-y-2 text-base md:text-lg ml-4">
                <li>Analizar y entender los objetivos de tu empresa.</li>
                <li>Identificar y optimizar procesos clave.</li>
                <li>Implementar soluciones tecnológicas innovadoras.</li>
                <li>Desarrollar estrategias de transformación digital.</li>
                <li>Establecer métricas de éxito.</li>
              </ul>
            </div>
          </div>

          {/* Sección de Servicios Detallados */}
          <div className="space-y-6 md:space-y-8 px-4">
            <h2 className="text-2xl md:text-3xl font-semibold text-center text-blue-600">
              Servicios de Consultoría Especializados
            </h2>
            <p className="text-base md:text-lg leading-relaxed text-center max-w-4xl mx-auto">
              Nuestro servicio de consultoría se adapta a diversos sectores y
              necesidades. Desarrollamos desde estrategias de mercado hasta
              planes de transformación digital completos.
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
              {[
                {
                  title: "Transformación Digital",
                  description:
                    "Desarrollamos planes de transformación digital que potencian la eficiencia y el crecimiento empresarial.",
                },
                {
                  title: "Optimización de Procesos",
                  description:
                    "Identificamos y optimizamos procesos críticos, mejorando la productividad y reduciendo costos.",
                },
                {
                  title: "Estrategia de Innovación",
                  description:
                    "Implementamos estrategias de innovación que posicionan tu empresa como líder en tecnología.",
                },
              ].map((service, index) => (
                <div
                  key={index}
                  className="bg-blue-50 p-4 md:p-6 rounded-lg shadow-sm text-center"
                >
                  <h3 className="text-lg md:text-xl font-semibold text-blue-600 mb-2">
                    {service.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-700">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Botones de Contacto */}
          <div className="mt-8 md:mt-12 text-center space-y-4 px-4">
            <p className="text-lg md:text-xl font-medium text-blue-600">
              ¿Listo para llevar tu negocio al siguiente nivel?
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4 md:gap-6">
              <a
                href="https://wa.me/573023580862"
                className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsWhatsapp className="mr-2" /> WhatsApp
              </a>
              <a
                href="mailto:consultoria@matubyte.online"
                className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <MailCheck className="mr-2" /> Correo
              </a>
              <a
                href="tel:+573023580862"
                className="flex items-center justify-center px-4 md:px-6 py-3 text-base md:text-lg font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Contactar <ArrowRightCircle className="ml-2" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BusinessConsultingPage;
