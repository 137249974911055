// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ArrowRight } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 shadow-sm">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Fmb_logo-500.png?alt=media&token=b4ad37e7-18c1-4e58-905a-a5ef50906dcf"
              alt="MatuByte Logo"
              className="h-10 w-auto"
            />
            <span className="ml-2 text-xl font-bold text-blue-600">MatuByte</span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            <Link to="/" className="text-gray-700 hover:text-blue-600">Inicio</Link>
            <Link to="/matubyte/services" className="text-gray-700 hover:text-blue-600">Servicios</Link>
            <Link to="/matubyte/tecnologias" className="text-gray-700 hover:text-blue-600">Tecnologías</Link>
            <Link to="/matubyte/projects" className="text-gray-700 hover:text-blue-600">Portafolio</Link>
            {/* <a href="#testimonios" className="text-gray-700 hover:text-blue-600">Testimonios</a> */}
            <Link to="/matubyte/contact" className="text-gray-700 hover:text-blue-600">Contacto</Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
          <a href="#inicio" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Inicio</a>
          <a href="#servicios" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Servicios</a>
          <a href="#tecnologias" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Tecnologías</a>
          <a href="#portafolio" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Portafolio</a>
          <a href="#testimonios" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Testimonios</a>
          <a href="#agendar" className="block px-3 py-2 text-gray-700 hover:bg-blue-50 rounded-md">Agendar cita</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
