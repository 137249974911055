import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { User, Mail, Phone, MessageSquare, Calendar as CalendarIcon, Check } from 'lucide-react';
import 'react-datepicker/dist/react-datepicker.css';

const AppointmentScheduler = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [customTime, setCustomTime] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const timeToUse = selectedTime === 'personalizado' ? customTime : selectedTime;

    const message = `*Nueva cita agendada:*\n\n` +
                    `Nombre: ${formData.name}\n` +
                    `Email: ${formData.email}\n` +
                    `Teléfono: ${formData.phone}\n` +
                    `Fecha: ${selectedDate.toLocaleDateString()}\n` +
                    `Hora: ${timeToUse}\n` +
                    `Mensaje: ${formData.message}`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/573148035412?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank'); // Abre WhatsApp en una nueva pestaña

    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleTimeChange = (event) => {
    const value = event.target.value;
    setSelectedTime(value);
    if (value !== 'personalizado') {
      setCustomTime(''); // Reset custom time if a predefined time is selected
    }
  };

  const handleCustomTimeChange = (event) => {
    setCustomTime(event.target.value);
  };

  const availableTimes = Array.from({ length: 19 }, (_, i) => {
    const hour = Math.floor((i + 8) / 2);
    const minutes = (i % 2) * 30;
    return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Header Section */}
        <div className="text-center py-12 px-6 bg-gradient-to-br from-blue-50 to-white">
          <div className="mx-auto w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center mb-6">
            <CalendarIcon className="w-10 h-10 text-blue-600" />
          </div>
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Agendar Cita</h2>
          <p className="text-gray-600 text-lg">Programa tu cita en simples pasos</p>
        </div>

        {/* Form Section */}
        <div className="p-8">
          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Date and Time Selection */}
            <div className="space-y-4">
              <label className="text-sm font-semibold text-gray-700 block">
                Selecciona la fecha y hora *
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()} // Restricción para no permitir días anteriores
                  className="w-full pl-12 py-3 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                  placeholderText="Selecciona una fecha"
                  isClearable
                />
                <select
                  value={selectedTime}
                  onChange={handleTimeChange}
                  className="w-full py-3 px-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                >
                  <option value="" disabled>Selecciona una hora</option>
                  {availableTimes.map((time) => (
                    <option key={time} value={time}>{time}</option>
                  ))}
                  <option value="personalizado">Personalizado</option>
                </select>
                {/* Campo para hora personalizada */}
                {selectedTime === 'personalizado' && (
                  <input
                    type="time"
                    value={customTime}
                    onChange={handleCustomTimeChange}
                    className="w-full py-3 px-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                    required
                  />
                )}
              </div>
            </div>

            {/* Selected Date and Time Summary */}
            {selectedDate && (selectedTime || customTime) && (
              <div className="bg-blue-50 p-6 rounded-xl border-2 border-blue-100">
                <h3 className="font-semibold text-gray-900 mb-2">Resumen de tu cita</h3>
                <p className="text-gray-700">{selectedDate.toLocaleDateString()} a las {selectedTime === 'personalizado' ? customTime : selectedTime}</p>
              </div>
            )}

            {/* Personal Information */}
            <div className="grid md:grid-cols-2 gap-6">
              {[{
                id: 'name',
                label: 'Nombre completo *',
                icon: User,
                placeholder: 'Juan Pérez',
                required: true
              },
              {
                id: 'email',
                label: 'Correo electrónico *',
                icon: Mail,
                placeholder: 'juan@ejemplo.com',
                required: true,
                type: 'email'
              },
              {
                id: 'phone',
                label: 'Número de teléfono *',
                icon: Phone,
                placeholder: '+1 234 567 890',
                required: true,
                type: 'tel'
              },
              {
                id: 'message',
                label: 'Mensaje (opcional)',
                icon: MessageSquare,
                placeholder: 'Escribe aquí cualquier detalle adicional...',
                rows: 3
              }].map((input) => (
                <div key={input.id} className="space-y-2">
                  <label htmlFor={input.id} className="block text-sm font-semibold text-gray-700">
                    {input.label}
                  </label>
                  <div className="relative group">
                    <input.icon className="absolute left-4 top-3.5 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" />
                    {input.rows ? (
                      <textarea
                        id={input.id}
                        name={input.id}
                        rows={input.rows}
                        placeholder={input.placeholder}
                        value={formData[input.id]}
                        onChange={handleInputChange}
                        className="w-full pl-12 py-3 px-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 resize-none"
                      />
                    ) : (
                      <input
                        type={input.type || 'text'}
                        id={input.id}
                        name={input.id}
                        required={input.required}
                        placeholder={input.placeholder}
                        value={formData[input.id]}
                        onChange={handleInputChange}
                        className="w-full pl-12 py-3 px-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-4 rounded-xl transition-all duration-200 flex items-center justify-center space-x-3 text-lg font-medium shadow-lg hover:shadow-xl"
            >
              <CalendarIcon className="h-6 w-6" />
              <span>Confirmar Cita</span>
            </button>
          </form>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-white p-8 rounded-2xl shadow-2xl text-center max-w-sm mx-4">
            <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <Check className="w-8 h-8 text-green-600" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-2">¡Cita confirmada!</h3>
            <p className="text-gray-600 mb-6">Tu cita ha sido registrada correctamente.</p>
            <button
              onClick={() => setShowSuccess(false)}
              className="w-full py-3 px-4 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-xl hover:from-green-600 hover:to-green-700 transition-all duration-200"
            >
              Aceptar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentScheduler;
