// TechnologiesSection.js
import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

const technologies = [
  { name: 'React', icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' },
  { name: 'Node.js', icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg' },
  { name: 'Python', icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg' },
  { name: 'AWS', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg' },
  { name: 'MongoDB', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg' },
  { name: 'Docker', icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Docker_logo.svg' },
  // Agrega más tecnologías según necesites
];

const TechnologiesSection = () => {
  return (
    <>
    <Navbar/>
    <section id="tecnologias" className="py-20 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-12">Nuestras Tecnologías</h2>
        
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center hover:shadow-lg transition-shadow duration-300"
            >
              <img src={tech.icon} alt={`${tech.name} logo`} className="h-16 w-16 mb-4" />
              <p className="text-lg font-medium text-gray-700">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    <Footer/>

    </>
  );
};

export default TechnologiesSection;
